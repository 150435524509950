<template>
    <a-modal :visible="visible" title="设置海报内容" destroyOnClose :maskClosable="false" :confirm-loading="confirmLoading"  @ok="handleOk" @cancel="handleCancel">
      <a-form
        ref="form"
        :model="formState"
        :validateOnRuleChange="false"
        name="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        scrollToFirstError
      >
        <a-form-item
          label="达人名称"
          name="name"
          :rules="[{ required: true, message: '输入达人名称' }]"
        >
          <a-input v-model:value="formState.name" name="name" placeholder="达人名称" />
        </a-form-item>
        <a-form-item
          label="门店总数"
          name="count"
          :rules="[{ required: true, message: '输入车间门店总数' }]"
        >
          <a-input v-model:value="formState.count" name="count" placeholder="车间门店总数" />
        </a-form-item>
        <a-form-item
          label="分布地图"
          name="url"
          :rules="[{ required: true, message: '输入车间分布地图url' }]"
        >
          <a-input v-model:value="formState.url" name="url" placeholder="将图片发给Jarvis获取链接" />
        </a-form-item>
        <a-form-item
          label="giga产品"
          name="giga"
          :rules="[{ required: true, message: '输入giga产品' }]"
        >
          <a-input v-model:value="formState.giga" name="giga" placeholder="giga产品" />
        </a-form-item>
        <a-form-item
          label="适用车型"
          name="type"
        >
          <a-input v-model:value="formState.type" name="type" placeholder="选填" />
        </a-form-item>
      </a-form>
    </a-modal>
  </template>
  <script>
  import { ref, watch } from 'vue';
  export default {
    name: 'vehicleBrandForm',
    props: ['visible', 'formData', 'confirmLoading'],
    setup(props) {
      const formState = ref(props.formData)
      watch(() => props.formData, (newVal) => formState.value = newVal)
      return {
        formState,
        form: ref(null),
      };
    },
    
    methods: {
      handleOk() {
        this.form.validateFields().then(res => {
          let formState = Object.assign(this.formState, res)
          this.$emit('formOk', formState)
        }).catch(err => {
          console.error(err)
          this.$message.error(err.errorFields[0].errors[0])
        })
      },
      handleCancel() {
        this.$emit('formCancel')
      },
    },
  
  }
  </script>